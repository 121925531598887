@import "fontawesome.css";
@font-face {
	font-family: IRANSansBold;
	font-style: normal;
	font-weight: bold;
	src: url("../fonts/ttf/IRANSansWeb_Bold.ttf") format("truetype");
}

@font-face {
	font-family: IRANSansMedium;
	font-style: normal;
	font-weight: 500;
	src: url("../fonts/ttf/IRANSansWeb_Medium.ttf") format("truetype");
}

@font-face {
	font-family: IRANSansLight;
	font-style: normal;
	font-weight: 300;
	src: url("../fonts/ttf/IRANSansWeb_Light.ttf") format("truetype");
}

@font-face {
	font-family: IRANSansRegular;
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/ttf/IRANSansWeb.ttf") format("truetype");
}

* {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
	outline: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-family: IRANSansRegular, "Roboto", sans-serif;
	cursor: default;
	border: none;
	scrollbar-width: thin;
	scrollbar-color: #ba68cb #f2edf3;
}

body,
html,
#root {
	width: 100%;
	height: 100%;
}

*::-webkit-scrollbar {
	width: 5px;
}

*::-webkit-scrollbar-thumb {
	background-color: #ba68cb;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

*::-webkit-scrollbar-track {
	background-color: #f2edf3;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.fal {
	font-family: "Font Awesome 5 Pro" !important;
}

.container-fluid {
	overflow: hidden;
}

.bg-main {
	background-color: #f5f1f5;
}

.login-card {
	max-width: 471px;
	/*height: 402px;*/
	border-radius: 8px;
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	position: relative;
	padding: 90px 45px 36px;
	margin: 0 auto;
}

.login-image {
	width: 181px;
	height: 181px;
	border-radius: 8px;
	-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background-image: url("../img/login_image.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 60%;
}

.login-input-label {
	width: 100%;
	font-family: IRANSansLight;
	font-size: 14px;
	font-weight: 300;
	text-align: right;
	color: #9c929d;
	margin-bottom: 4px;
}

.login-input,
.login-input:focus {
	width: 100%;
	height: 48px;
	border-radius: 3px;
	border: solid 1px #eadcef !important;
	background-color: #ffffff;
	color: #4d3f4e;
	font-size: 18px;
	margin-bottom: 10px;
}

.login-input::placeholder {
	text-align: right;
	font-size: 14px;
	color: #d9d9d9;
	font-family: IRANSansLight;
}

.login-error-box {
	height: 22px;
	text-align: center;
	line-height: 22px;
	font-size: 14px;
	color: #f44336;
	margin: 15px 0 20px;
	transition: 300ms;
}

.login-btn {
	width: 100%;
	height: 48px;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	background-color: #9c27b0;
	color: #fff;
	font-size: 18px;
	font-family: IRANSansLight;
	display: flex;
	justify-content: center;
	align-items: center;
}

.right-side-menu {
	width: 62px;
	height: 100%;
	background-color: #9c27b0;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 2000;
}

ul.side-menu {
	width: 100%;
	padding: 100px 12px 0;
}

ul.side-menu > li {
	width: 100%;
	height: 38px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 22px;
	text-align: center;
	margin-bottom: 20px;
	transition: 0.4s;
}

ul.side-menu > li.selected {
	background-color: #ba68c8;
}

.invite-link {
	font-size: 14px;
	font-family: IRANSansLight;
	color: #9c27b0 !important;
	margin-left: 30px;
	cursor: pointer;
}

.switch,
.filter-switch {
	position: relative;
	display: inline-block;
	width: 88px;
	height: 30px;
}

.filter-switch {
	width: 37px;
	height: 18px;
}

.switch input,
.filter-switch input {
	display: none;
}

.slider,
.filter-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 1px solid #9c929d;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	font-size: 14px;
	padding: 4px 10px;
}

.filter-slider {
	padding: 4px 18px;
	border: 1px solid #eadcef;
}

.slider:before,
.filter-slider:before {
	position: absolute;
	content: "\f056";
	height: 24px;
	width: 24px;
	left: 4px;
	bottom: 2px;
	background-color: #9c929d;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	font-family: "Font Awesome 5 Pro";
	text-align: center;
	color: #fff;
	font-size: 16px;
}

.filter-slider:before {
	content: "";
	width: 14px;
	height: 14px;
	left: 1px;
	bottom: 1px;
	background-color: #eadcef;
}

input:checked + .slider,
input:checked + .filter-slider {
	/*background-color: #2196F3;*/
	border: 1px solid #eadcef;
	content: attr(data-on);
	padding: 4px 40px;
}

input:checked + .filter-slider {
	padding: 5px 18px;
}

input:focus + .slider,
input:focus + .filter-slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	content: "\f058";
	background-color: #9c27b0;
	-webkit-transform: translateX(54px);
	-ms-transform: translateX(54px);
	transform: translateX(54px);
}

input:checked + .filter-slider:before {
	content: "";
	background-color: #9c27b0;
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}

.slider.round {
	border-radius: 34px;
}

.filter-slider.round {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.slider.round:before {
	border-radius: 50%;
}

.filter-slider.round:before {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.supporter-status {
	margin-left: 16px;
}

.status-deactive {
	border: solid 1px #9c929d;
	color: #9c929d;
}

.status-active {
	border: solid 1px #9c27b0;
	color: #9c27b0;
}

.status {
	background-color: #ffffff;
	width: 88px;
	height: 30px;
	border: solid 1px #ebd3ef;
	line-height: 30px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 16px;
}

.status-online {
	color: #9c27b0;
}

.status-offline {
	color: #9c929d;
}

.status-icon {
	width: 24px;
	height: 24px;
	box-shadow: 0 0 4px 0 #eadcef;
	border-radius: 50%;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	margin-right: 3px;
}

.check-icon {
	background-color: #9c27b0;
}

.powerOff-icon {
	background-color: #9c929d;
}

.chat-box {
	border: 1px solid #d5d7db;
}

.chat-box-image {
	background-image: url("../img/chat_box_back.png");
	background-position: center;
	background-size: 50%;
	background-repeat: no-repeat;
	position: relative;
	top: 0;
	left: 0;
}

.chat-box-image:after {
	content: "برای شروع وضعیت خود را به آنلاین تغییر دهید و کاربر مورد نظر را از لیست سمت چپ انتخاب نمایید";
	display: block;
	width: 300px;
	height: 200px;
	font-size: 18px;
	color: #9c27b0;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
}

.message-badge {
	width: 22px;
	height: 22px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	position: relative;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-name-wrapper {
	width: calc(100% - 104px);
}

.last-message {
	font-size: 10px;
	color: #9c929d;
	margin-top: 7px;
}

.list-group li.active .last-message,
.list-group li.active .message-date {
	color: #fff;
}

.list-group li:hover,
.list-group li div:hover,
.list-group li img:hover {
	cursor: pointer;
}

.message-badge > span {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 12px;
	font-family: IRANSansBold;
}

.badge-join {
	width: 10px;
	height: 10px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.badge-join:after {
	content: "";
	display: block;
	width: 4px;
	height: 4px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #fff;
}

.bg-light {
	background-color: #d6d6d6 !important;
}

.ltr {
	direction: ltr !important;
}

input:focus,
.form-control:focus {
	/* border: none; */
	outline: none;
	z-index: unset !important;
	box-shadow: none;
}

.no-padding {
	padding: 0 !important;
}

.row-height {
	height: 100vh;
}

.chat-page {
	width: 100%;
	height: 100%;
	position: relative;
}

.list-group {
	/*margin-top: 62px;*/
	padding: 16px !important;
}

.list-group li {
	height: auto;
	border: none;
	-webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	background-color: #fcfcfc;
	-webkit-border-radius: 8px !important;
	-moz-border-radius: 8px !important;
	border-radius: 8px !important;
	margin-bottom: 8px;
	padding: 14px;
	direction: rtl;
	flex: 1 0 auto !important;
}

.list-group li:hover {
	background-color: #fbf6fc;
}

.list-group li.active {
	background-color: #9c27b0;
}

.chat-scrollable {
	/* height: auto;
    min-height: calc(100% - 89px); */
	height: calc(100% - 96px);
	overflow-y: scroll;
	/* overflow-x: hidden; */
	-webkit-overflow-scrolling: touch;
	padding: 0 8px 0;
}

.chat-data {
	padding: 20px 16px 10px;
}

.chat-data-diet-btn.save-btn {
	color: #9c27b0;
	text-align: center;
	font-size: 14px;
	font-family: IRANSansLight;
	margin-top: 8px;
	cursor: pointer;
}

.chat-data-diet-btn,
.chat-data-diet-btn:hover {
	width: 100%;
	height: 48px;
	margin-bottom: 8px;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.chat-data-diet-btn:hover {
	background-color: #fbf6fc;
}

.chat-data-diet-btn:hover * {
	cursor: pointer;
}

.chat-data-diet-btn div {
	color: #9c27b0;
	text-align: center;
	font-size: 14px;
	font-family: IRANSansLight;
}

.chat-data-header {
	width: 100%;
	font-size: 12px;
	font-family: IRANSansLight;
	margin: 8px 0 4px;
}

.chat-data-box {
	width: 100%;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background-color: #fff;
}

.chat-data-subject {
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: right;
	color: #4d3f4e;
	margin-top: 10px;
	margin-bottom: 4px;
}

.sales-link-wrapper {
	width: 100%;
	height: 80px;
	/*box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);*/
	/*background-color: #f5f1f5;*/
	/*position: absolute;*/
	/*bottom: 0;*/
	/*left: 0;*/
	padding: 0 16px;
	display: flex;
	align-items: center;
}

.chat-message:first-child {
	margin-top: auto !important;
}

.chat-message {
	max-width: 70%;
	min-width: 120px;
	padding: 14px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin: 4px 0;
	word-break: break-word;
	white-space: pre-wrap;
	font-size: 16px;
	color: #4d3f4e;
	font-family: IRANSansLight;
	direction: rtl;
	text-align: right;
	position: relative;
}

/* .chat-message-self::before{
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  right: -15px;
  bottom: 0;
  background-color: #eadcef;
}
.chat-message-self::after{
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  right: -30px;
  bottom: 0;
  border-radius: 50%;
  background-color: #f5f1f5;
  z-index: 1;
}
.chat-message-other::before{
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: -15px;
  bottom: 0;
  background-color: #fff;
}
.chat-message-other::after{
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  left: -30px;
  bottom: 0;
  border-radius: 50%;
  background-color: #f5f1f5;
  z-index: 1;
} */

.chat-date {
	width: 90px;
	height: 35px;
	margin: 0 auto;
	padding: 10px;
	border-radius: 22px;
	background-color: rgba(234, 220, 239, 0.5);
	color: #9c929d;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chat-time {
	color: #9c27b0;
	font-size: 12px;
	position: absolute;
	bottom: 5px;
}

.chat-message-other {
	/* left:15px; */
	background-color: #fff;
	align-self: flex-start;
	border-bottom-left-radius: 0;
}

.chat-status {
	color: #9c27b0;
	font-size: 10px;
	position: absolute;
	bottom: 7px;
    right: auto;
    left: 10px;
}

.chat-message-self {
	/* right:15px; */
	background-color: #eadcef;
	align-self: flex-end;
	border-bottom-right-radius: 0;
}

.main-navbar {
	width: 100%;
	height: 62px;
	background-color: #fff;
	-webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 75px 0 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1999;
	direction: rtl;
}

.main-navbar > div {
	width: auto;
	height: 100%;
	display: flex;
	align-items: center;
}

.user-image {
	width: 42px;
	height: 42px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	margin-left: 8px;
}

.user-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.limoome-member {
	width: 16px;
	height: 16px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #fff;
	background-image: url("../img/limoome-member.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80%;
	border: 1px solid #fcee21;
	transform: translate(0%, -100%);
}

.supporter-name {
	font-size: 14px;
	font-family: IRANSansLight;
	color: #4d3f4e;
}

.chat-navbar {
	width: 100%;
	height: 40px;
	-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	position: fixed;
	top: 62px;
	left: 0;
	z-index: 3;
	background-color: #faf4fb;
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
}

.file {
	width: 300px;
	max-width: 100%;
	height: 50px;
	direction: ltr;
	cursor: pointer;
	display: flex;
	align-items: center;
	/*background-color: #fff;*/
}

.file:hover {
	text-decoration: none;
}

.file *,
.file *:before,
.file *:after {
	cursor: pointer;
}

.file > span {
	margin-left: 10px;
	color: #4d3f4e;
}

.file-icon {
	width: 48px;
	height: 48px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.file-icon span {
	color: #9c27b0;
	font-size: 18px;
}

.chat-message-other .file-icon {
	background-color: #eadcef;
}

.chat-message-self .file-icon {
	background-color: #fff;
}

.back-btn {
	font-size: 10px;
}

.chat-page-bottom-wrapper {
	width: 100%;
	height: auto;
	padding: 8px 8px 16px;
	background-color: #f5f1f5;
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
}

.input-group {
	width: 100%;
	min-height: 72px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background-color: #fff;
	-webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
	overflow: hidden;
	display: flex;
	align-items: center;
}

#message-input,
#message-input::placeholder {
	font-family: IRANSansLight;
	font-size: 16px;
	color: #9c929d;
	border: none;
}

#message-input {
	color: #4d3f4e;
	resize: none;
}

.chat-close-btn {
	width: 100px;
	height: 100%;
	background-color: #b1dfbb;
	display: flex;
	justify-content: center;
	align-items: center;
}

.voice-link {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pdf-download-btn-wrapper {
	width: 100%;
	height: 200px;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: red;
}

.health-data-wrapper {
	width: 100%;
	height: 30px;
	background-color: #e9e9e9;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.health-data-wrapper:first-child {
	margin-top: 30px;
}

.health-data-name {
	min-width: 66px;
	margin-left: 24px;
	color: #948b94;
	font-size: 12px;
	font-family: IRANSansLight;
	display: inline-block;
}

.health-data-row {
	min-height: 38px;
	display: flex;
	align-items: center;
	background-color: #ffffff;
	padding: 0 16px;
}

.health-data-row:first-of-type {
	border-radius: 8px 8px 0 0;
}

.health-data-row:last-of-type {
	border-radius: 0 0 8px 8px;
}

.health-data-row:nth-child(even) {
	background-color: #fcfcfc;
}

.health-data-detail {
	height: 18px;
	background-color: #f0def3;
	border-radius: 0 0 8px 8px;
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: normal;
	text-align: center;
	color: #9c27b0;
	cursor: pointer;
}

.health-data-detail > span {
	cursor: pointer;
}

.change-diet-date-icon {
	position: absolute;
	left: 0;
	margin-left: 16px;
	color: #9c27b0;
	font-size: 19px;
	cursor: pointer;
}

.health-data-value {
	color: #4d3f4e;
	font-size: 14px;
	font-family: IRANSansLight;
}

.limoome-user {
	width: 30px;
	height: 30px;
	padding: 10px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #4e555b;
	float: right;
	margin-left: 10px;
	background-image: url("../img/limoome-logo.png");
	background-repeat: no-repeat;
	background-size: 70%;
	background-position: center;
}

.photo-message,
.video-message {
	width: 200px;
	max-height: 500px;
	object-fit: contain;
	object-position: center;
	/*padding: 3px 2px;*/
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}

.photo-message-target-container {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	z-index: 1000;
	display: flex;
	background-color: rgba(0, 0, 0, 0.8);
	transition: opacity 0.35s;
	opacity: 0;
	width: 0;
}

.photo-message-target-container .photo-message-target {
	display: block;
	width: 300px;
	margin: auto;
}

.photo-message-target-container:target {
	opacity: 1;
	width: 100%;
}

.video-message {
	width: 300px;
}

.file-upload {
	padding: 0 15px;
	font-size: 22px;
	color: #9c27b0;
	position: relative;
}

#file-upload {
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
}

.send-message-btn {
	font-size: 21px;
	padding: 12px 12px 12px 10px;
	display: flex;
	background-color: #9c27b0;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin: 0 12px;
}

.send-message-btn:active {
	outline: unset;
}

.send-message-btn:hover path {
	cursor: pointer;
}

.record-btn {
	padding: 0 15px;
	font-size: 22px;
	color: #9c27b0;
	background-color: unset;
	display: flex;
}

.record-btn:active {
	outline: unset;
}

.record-btn:hover,
.record-btn:hover span {
	cursor: pointer;
}

.popup-wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-backdrop-filter: blur(7px);
	backdrop-filter: blur(7px);
	background-color: rgba(145, 142, 145, 0.8);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup-box {
	width: 450px;
	background-color: #fff;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 35px 29px;
	position: relative;
	top: 0;
	left: 0;
	overflow: hidden;
}

.popup-image {
	width: 100px;
	height: 100px;
}

.popup-title {
	width: 100%;
	color: #9c27b0;
	text-align: center;
	margin-top: 10px;
}

.invite-link-wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0 25px;
	cursor: pointer;
}

.popup-invite-link {
	width: 90%;
	background-color: #eadcef;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	color: #9c27b0;
	padding: 13px 10px;
	float: left;
	display: inline-block;
	cursor: pointer;
}

.popup-invite-link-icon {
	display: inline-block;
	font-family: "Font Awesome 5 Pro";
	font-weight: bold;
	color: #9c27b0;
	font-size: 20px;
	cursor: pointer;
}

.popup-paragraph {
	width: 80%;
	margin: 10px auto 30px;
	font-size: 14px;
	color: #4d3f4e;
	font-family: IRANSansLight;
	text-align: center;
}

.popup-close-btn {
	width: 36px;
	height: 36px;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #9c929d;
	font-size: 18px;
	cursor: pointer;
}

.popup-btn {
	width: 90px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.popup-btn span {
	color: #9c929d;
	font-size: 14px;
}

.popup-btn span:last-child {
	font-size: 18px;
}

.audio-recorder-stop-wrapper {
	border: solid 1px #9c27b0;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin: 0 14px;
}

.audio-recorder-stop {
	width: 18px;
	height: 18px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background-color: #9c27b0;
	padding: 0;
	margin: 11px 10px;
}

.recording-progress {
	flex: 1 1 auto;
	height: 50px;
	background-color: #af52bf;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 14px;
}

.recording-timer {
	width: 50px;
	color: #fff;
	text-align: left;
}

.recording-text {
	display: flex;
	align-items: center;
	color: #fff;
}

.recording-indicator {
	width: 16px;
	height: 16px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #fff;
	margin-left: 5px;
	animation-name: recording;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

.audio-play-btn {
	color: #fff;
}

@keyframes recording {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.8);
	}
}

.chart-wrapper {
	height: 95vh;
	width: 100%;
	margin: 0 auto;
	position: relative;
	display: flex;
	align-items: center;
}

.chart-option-wrapper {
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background-color: #fff;
}

#myChart {
	/* max-height: 570px;
  position: absolute;
  left: 0;
  bottom: 0; */
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background-color: #fff;
	-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.DatePicker {
	z-index: 1000 !important;
}

/* .DatePicker input{
  display: none;
} */

.DatePicker::after {
	display: none;
}

/* .DatePicker > * {
  left: 0 !important;
  transform: translate(0, 0) !important;
} */

.diet-date-picker-wrapper .Calendar {
	width: 27em;
	padding-top: 1.5em;
}

.diet-date-picker-wrapper .Calendar__section {
	padding: 0 2em;
}

.diet-date-picker-wrapper
	.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
	display: none;
}

.diet-date-picker-wrapper
	.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
	background-color: #f3f3f3;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}

.Calendar__weekDays {
	background-color: #f0def3;
	color: #9c27b0 !important;
}

.Calendar__monthYear button {
	color: #948b94 !important;
}

.diet-date-picker-wrapper .Calendar__day.-selected {
	background-color: #c37dcf;
	border: solid 1px #9c27b0;
	color: #fff;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
}

.diet-date-picker-wrapper .DatePicker__calendarContainer {
	/* margin-left: -90px; */
	/* transform: scale(0.8) !important; */
	/* top: 0; */
	/* position: unset;
  left: unset;*/
	top: calc(100% + 5px);
	/* transform: unset;  */
	/* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3); */
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.report-page-title {
	color: #4d3f4e;
	font-size: 14px;
	font-family: IRANSansLight;
}

.supporter-report-card {
	height: 122px;
	background-color: #fff;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.supporter-role {
	font-size: 12px;
	color: #9c929d;
}

.supporter-report-status-active,
.supporter-report-status-deactive {
	display: inline-block;
	font-size: 14px;
	position: relative;
	padding: 0 5px 0 16px;
	font-family: IRANSansLight;
	color: #2196f3;
}

.supporter-report-status-deactive {
	color: #9c929d;
}

.supporter-report-status-active:before,
.supporter-report-status-deactive:before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #2196f3;
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translateY(-50%);
}

.supporter-report-status-deactive:before {
	background-color: #9c929d;
}

.report-card-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #9c27b0;
	font-size: 14px;
	font-family: IRANSansLight;
	cursor: pointer;
}

.report-card-btn span {
	margin-left: 6px;
	font-size: 16px;
}

.supporter-daily-report-popup-wrapper {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	background-color: rgba(145, 142, 145, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
}

.supporter-daily-report-popup {
	width: 80%;
	height: 75vh;
	margin: 0 auto;
	background-color: #fff;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
}

.report-popup-supporter-info {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px 0;
}

.report-popup-supporter-info > div {
	margin: 3px 0;
}

.report-popup-box {
	height: 67px;
	margin: 5px;
	background-color: #fdfdfd;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding: 8px 16px 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.report-popup-box-title {
	width: 100%;
	text-align: center;
	color: #9c929d;
	font-size: 12px;
	font-family: IRANSansLight;
}

.report-popup-box-value {
	width: 100%;
	text-align: center;
	color: #4d3f4e;
	font-size: 18px;
	font-family: IRANSansLight;
}

.date-picker-wrapper > * {
	margin-left: 10px;
}

.diet-date-picker-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}

.diet-date-picker-submit-btn-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.diet-date-picker-submit-btn {
	width: 30px;
	height: 30px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	background-color: #fbf6fc;
	cursor: pointer;
}

.chart-refresh-btn {
	color: #9c27b0;
	cursor: pointer;
}

.connection-wrapper {
	justify-content: center;
	align-items: center;
}

.connection-status {
	font-size: 14px;
	color: #9c27b0;
	cursor: pointer;
}

.sync-animation {
	animation-name: syncRotate;
	animation-iteration-count: infinite;
	animation-duration: 1s;
	transform: rotate(0deg);
	transform-origin: center;
	animation-timing-function: linear;
}

@keyframes syncRotate {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.chat-list-header {
	width: 100%;
	min-width: 100%;
	height: 111px;
	/*background-color: red;*/
	padding: 16px;
	-webkit-box-shadow: 0 2px 4px 0 rgba(70, 14, 80, 0.1);
	-moz-box-shadow: 0 2px 4px 0 rgba(70, 14, 80, 0.1);
	box-shadow: 0 2px 4px 0 rgba(70, 14, 80, 0.1);
	position: relative;
	top: 0;
	left: 0;
}

.chat-list-header > span {
	font-family: IRANSansLight;
}

.chat-list-header ul.tag-list-drop-down {
	transform: none;
	width: 100%;
	box-shadow: none;
	border: solid 1px #c37dcf;
	border-top: unset;
	border-radius: unset;
}

.search-input {
	height: 48px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	border: solid 1px #eadcef;
	background-color: #fff;
	font-size: 12px;
	color: #9c929d;
}

.search-input:focus {
	border-color: #c37dcf !important;
}

.search-input::placeholder {
	color: #9c929d;
}

.search-icon {
	position: absolute;
	top: 17px;
	left: 15px;
	color: #9c929d;
}

.filter-btn {
	width: 100%;
	color: #9c27b0;
	font-size: 12px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
	margin: 0 5px;
	transition: all ease 0.3s;
}

.filter-btn:hover {
	color: #581064;
}

.filter-btn span {
	margin-right: 10px;
	font-family: IRANSansLight;
	cursor: pointer;
}

.filter-btn .fal {
	font-size: 18px;
	font-family: "Font Awesome 5 Pro";
}

.filter {
	width: 100%;
	/*height: 38px;*/
	/*max-height: 400px;*/
	background-color: #fff;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	font-weight: 100;
	transition: 300ms;
	transition-property: max-height;
	overflow: hidden;
}

.filter-top-wrapper {
	cursor: pointer;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filter-name {
	font-size: 14px;
	cursor: inherit;
	color: #9c929d;
	font-family: IRANSansLight;
}

.filter-toggle-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.filter-submit-wrapper {
	width: calc(100% - 20px);
	display: flex;
	justify-content: space-between;
	margin: 10px;
	position: absolute;
	bottom: 0;
}

.filter-submit-wrapper * {
	cursor: pointer;
}

.filter-submit-btn {
	width: 146px;
	height: 48px;
	background-color: #9c27b0;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	font-family: IRANSansLight;
}

.filter-submit-btn.disabled {
	background-color: #eadcef;
	color: #9c929d;
}

.filter-back-btn {
	width: 146px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: IRANSansLight;
}

.filter-option-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 15px 0 6px;
}

.filter-option-wrapper label input {
	display: none;
}

.filter-option-wrapper input {
	/*display: none;*/
	min-width: 25px;
	max-height: 20px;
	background-color: #e9e9e9;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	text-align: center;
}

.tags-filter {
	height: 400px;
	justify-content: flex-start;
}

.filter-tags-select {
	width: 200px !important;
}

#diet-end > *,
#weight-diff > *,
#diet-count > * {
	margin: 0 5px;
	font-size: 12px;
}

#diet-end input {
	display: inline-block;
	width: 20px;
	height: 40px;
	margin: 0 5px;
}

.filter-option-wrapper * {
	font-family: IRANSansLight;
}

.filter-option-btn {
	width: 100px;
	height: 36px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background-color: #fbfbfb;
	border: 1px solid #fbfbfb;
	color: #9c929d;
	transition: 300ms;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 7px;
}

.filter-option-label {
	margin-bottom: 0;
}

.filter-option-label input:checked ~ .filter-option-btn {
	border: 1px solid #9c27b0;
	background-color: transparent;
	color: #9c27b0;
}

.diet-day-label {
	width: 100%;
	margin-bottom: 0;
}

.diet-day-btn {
	width: 100%;
	height: 40px;
	border: 1px solid #fafafa;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background-color: #fafafa;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 300ms;
	font-size: 12px;
	color: #9c929d;
}

.diet-day-label input:checked ~ .diet-day-btn {
	color: #9c27b0;
	border: 1px solid #9c27b0;
}

.tag-card-wrapper {
	width: 100%;
	/*height: 122px;*/
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background-color: #fff;
	margin: 15px 0 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	top: 0;
	left: 0;
}

.tag-card-wrapper p {
	font-size: 14px;
	color: #9c929d;
	font-family: IRANSansLight;
}

.tag-card-top-wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.tag-card-bottom-wrapper {
	width: 100%;
	height: 40px;
	/*background-color: red;*/
}

.tag-add-btn {
	width: 32px;
	height: 32px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	cursor: pointer;
	margin: 0 10px 0 0;
}

.tag-remove-btn {
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.3);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	margin: 0 4px 0 0;
	cursor: pointer;
}

.tag-counter {
	/*width: 35px;*/
	padding: 0 5px;
	height: 24px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tag-counter span {
	font-size: 12px;
	margin: 0 2px;
}

ul.tag-list-drop-down {
	width: 150px;
	background-color: #fff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	box-shadow: 0 0 10px 0 rgba(22, 4, 26, 0.295);
	position: absolute;
	left: 0;
	top: 100%;
	z-index: 1000;
	transform: translate(10px, -13px);
	direction: rtl;
	max-height: 50vh;
	overflow-y: scroll;
}

.tag {
	width: fit-content;
	padding: 5px;
	margin: 4px 0 4px 8px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	font-size: 14px;
	font-family: IRANSansLight;
	color: #fff;
	background-color: #c37dcf;
	display: flex;
	direction: rtl;
}

ul.tag-list-drop-down li {
	width: 100%;
	font-size: 14px;
	font-family: IRANSansLight;
	display: flex;
	direction: rtl;
	padding: 5px 8px;
}

ul.tag-list-drop-down li:hover {
	cursor: pointer;
	background-color: #f6f5f6;
}

.join-card-tag-wrapper {
	width: 100%;
	/*background-color: red;*/
	/*padding-top: 12px;*/
	display: flex;
}

.join-card-tag-wrapper .tag {
	max-width: 78px;
	margin-left: 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-bottom: 0;
	font-size: 12px;
	display: block;
	background-color: #c37dcf;
	color: #fff;
}

.message-date-wrapper {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
}

.message-date {
	font-size: 10px;
	color: #9c929d;
}

.plyr__controls {
	padding: 0 !important;
}

.plyr--audio .plyr__controls {
	background-color: unset;
}

.plyr--full-ui input[type="range"] {
	color: #9c27b0;
}

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control,
.plyr--audio .plyr__control[aria-expanded="true"] {
	background-color: #9c27b0 !important;
}

.plyr--audio .plyr__control {
	padding: 0;
}

.plyr--audio .plyr__control .icon--pressed,
.plyr--audio .plyr__control .icon--not-pressed {
	fill: #fff;
}

.plyr--audio .plyr__control .icon--pressed {
	margin: 15px;
}

.plyr--audio .plyr__control .icon--not-pressed {
	margin: 15px 13px 15px 17px;
}

.plyr__control.plyr__tab-focus {
	box-shadow: unset;
	outline: 0;
}

.plyr__controls__item.plyr__control[type="button"] {
	border-radius: 50%;
}

.content-container {
	padding-right: 62px;
	padding-top: 62px;
	height: 100vh;
}

.chart-option-wrapper .date-picker-wrapper {
	display: flex;
	align-items: center;
}

.plyr--audio .plyr__progress__buffer {
	color: rgba(255, 255, 255, 0.66) !important;
}

input[type="range"]::-webkit-slider-thumb {
	visibility: hidden;
}

input[type="range"]::-moz-range-thumb {
	visibility: hidden;
}

.chat-message-other input[type="range"]::-moz-range-track {
	background-color: #f5e9f7 !important;
}

.chat-message-self input[type="range"]::-moz-range-track {
	background-color: #ffffff !important;
}

.chat-message-other input[type="range"]::-webkit-slider-runnable-track {
	background-color: #f5e9f7 !important;
}

.chat-message-self input[type="range"]::-webkit-slider-runnable-track {
	background-color: #ffffff !important;
}

.plyr__controls__item.plyr__progress__container {
	flex: 1;
}

.plyr--audio .plyr__control.plyr__tab-focus[data-plyr="download"],
.plyr--audio .plyr__control[data-plyr="download"] {
	background-color: unset !important;
	color: #9c27b0;
}

.send-arrow-button .cls-1 {
	fill: #fff;
}

.send-arrow-button:hover {
	cursor: pointer;
}

.recording-progress .plyr--audio button.plyr__controls__item.plyr__control {
	background-color: unset !important;
}

.recording-progress input[type="range"]::-webkit-slider-runnable-track {
	background-color: rgba(0, 0, 0, 0.1) !important;
}

.recording-progress input[type="range"]::-moz-range-track {
	background-color: rgba(0, 0, 0, 0.1) !important;
}

.recording-progress input[type="range"] {
	color: #fff;
}

/* .recording-progress .plyr__progress__buffer {
    color: rgba(0, 0, 0, 0.1) !important;
} */

.recording-progress .plyr {
	width: 95%;
}

.cancel-upload {
	color: #fff;
	font-size: 1.5em;
	cursor: pointer;
}

.chat-data-mobile {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 10000;
	width: 65%;
	max-width: 400px;
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
	cursor: pointer;
}

.modal-dialog {
	margin-top: 4.75rem !important;
}

.modal-title {
	direction: rtl;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: right;
	color: #9c27b0;
}

.modal-subTitle {
	direction: rtl;
	margin-top: 8px;
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	text-align: right;
	color: #4d3f4e;
}

.modal-paragraph {
	direction: rtl;
	margin-top: 24px;
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	text-align: right;
	color: #9c929d;
}

.modal-textarea {
	direction: rtl;
	margin-top: 8px;
	width: 468px;
	height: 200px;
	border-radius: 4px;
	border: solid 1px #eadcef;
	background-color: #ffffff;
	resize: none;
	padding: 8px;
}

.modal-textarea::placeholder {
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	text-align: right;
	color: #d4d0d5;
}

.modal-textarea-counter {
	direction: rtl;
	position: relative;
	top: -32px;
	left: 8px;
	font-size: 12px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.58;
	letter-spacing: normal;
	color: #9c929d;
	width: 65px;
	height: 24px;
	border-radius: 5px;
	background-color: #fafafa;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-buttons {
	direction: rtl;
	position: relative;
	top: -28px;
	margin-top: 16px;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal-close {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: center;
	color: #4d3f4e;
	background-color: #ffffff;
	margin-right: 53px;
	cursor: pointer;
}

.modal-submit {
	width: 325px;
	height: 48px;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	background-color: #9c27b0;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: normal;
	text-align: right;
	color: #ffffff;
	cursor: pointer;
}

.modal-submit > span {
	font-size: 24px;
	font-weight: 300;
	margin-right: 16px;
	margin-left: 8px;
}

.offline-container {
	margin-top: 85px;
	direction: rtl;
}

.offline-root {
	background-color: #fafafa;
	overflow: scroll;
}

.galaxy-left {
	background-image: url("../img/Galaxy-Left.png");
	background-repeat: no-repeat;
	background-position: top;
	margin-top: 30px;
}

.galaxy-right {
	background-image: url("../img/Galaxy-Right.png");
	background-repeat: no-repeat;
	background-position: top;
	margin-top: 30px;
}

.offline-title {
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	text-align: right;
	color: #9c27b0;
	margin-bottom: 4px;
}

.offline-subTitle {
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	text-align: right;
	color: #4d3f4e;
	margin-bottom: 16px;
	width: 447px;
}

.offline-label {
	font-size: 12px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.58;
	letter-spacing: normal;
	text-align: right;
	color: #4d3f4e;
	margin-bottom: 4px;
}

.offline-textarea {
	width: 447px;
	height: 263px;
	border-radius: 8px;
	border: solid 1px #ebd3ef;
	background-color: #ffffff;
	padding: 8px;
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	text-align: right;
	color: #4d3f4e;
	resize: none;
}

.offline-textarea:disabled {
	border: none;
	color: #9c929d;
}

.offline-textarea-hr {
	width: 431px;
	height: 1px;
	background-color: #ebd3ef;
	position: relative;
	top: -71px;
	border-top: none;
	right: 8px;
}

.offline-textarea-bottom {
	position: relative;
	height: 54px;
	top: -87px;
	display: flex;
	width: calc(100% - 16px);
	margin: 0 auto;
	align-items: center;
	justify-content: space-between;
	direction: ltr;
}

.offline-textarea-counter {
	direction: rtl;
	font-size: 12px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.58;
	letter-spacing: normal;
	color: #9c929d;
	width: 69px;
	height: 24px;
	border-radius: 5px;
	background-color: #fafafa;
	display: flex;
	justify-content: center;
	align-items: center;
}

.col-sm-0 {
	width: 0;
	padding: 0;
}

.offline-edit-button {
	width: 81px;
	height: 32px;
	border-radius: 8px;
	background-color: rgba(235, 211, 239, 0.49);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	color: #9c27b0;
	direction: rtl;
	cursor: pointer;
}

.offline-edit-button > span {
	margin-left: 4px;
}

.offline-textarea-submit {
	display: flex;
	justify-content: center;
	align-items: center;
	direction: rtl;
	width: 81px;
	height: 32px;
	border-radius: 8px;
	background-color: #9c27b0;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	text-align: right;
	color: #ffffff;
	cursor: pointer;
}

.offline-textarea-submit > span {
	margin-left: 4px;
}

.offline-textarea-cancel {
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	text-align: right;
	color: #4d3f4e;
	background-color: #ffffff;
	cursor: pointer;
	margin-left: 50px;
}

.offline-error-message {
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.07;
	letter-spacing: normal;
	text-align: right;
	color: #ee5353;
}

.offline-buttons {
	display: flex;
	justify-content: space-between;
	position: relative;
	top: -40px;
	width: 447px;
}

.offline-submit-button {
	width: 195px;
	height: 48px;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(70, 14, 80, 0.1);
	background-color: #9c27b0;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

.offline-exit-button {
	width: 195px;
	height: 48px;
	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(70, 14, 80, 0.1);
	background-color: #ffffff;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: center;
	color: #9c27b0;
}

.css-10nd86i {
	width: 150px;
}

.loader {
	border: 4px solid #f3f3f3;
	border-radius: 50%;
	border-top: 4px solid #3498db;
	width: 40px;
	height: 40px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
}

.loader-sm {
	border: 3px solid #f3f3f3;
	border-radius: 50%;
	border-top: 4px solid #3498db;
	width: 20px;
	height: 20px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.message-loading {
	width: 24px;
	height: 24px;
	background-color: red;
	position: absolute;
}
